@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .top-shadow {
        box-shadow: 0px -8px 16px 0 theme('colors.default.800');
    }
    .top-shadow-white {
        box-shadow: 0px -8px 16px 0 theme('colors.white');
    }
    .top-shadow-dark {
        box-shadow: 0px -8px 16px 0 theme('colors.default.500');;
    }

    .anchor-offset-top {
        offset-anchor: top;
    }
    
    .scroll-mt-18 {
        scroll-margin-top: 4.5rem;
    }
}

html {
    scroll-behavior: smooth;
}

